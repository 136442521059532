body {
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
}

h1 {
  text-align: center;
  margin-top: 80px
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 20px;
  position: fixed; /* Закрепляет меню */
  top: 0; /* Располагает меню в верхней части экрана */
  width: 100%; /* Меню занимает всю ширину экрана */
  z-index: 1000; /* Размещает меню поверх других элементов */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Тень для визуального отделения */
  box-sizing: border-box; /* Учитывает padding и border в ширине и высоте элемента */
}

.menu a {
  margin: 0 15px;
  text-decoration: none;
  color: #000;
  font-weight: normal;
}

.menu a.active {
  font-weight: bold;
}

.menu .profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
} 
 

.portfolio {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  overflow-y: auto;
  padding: 20px;
  padding-top: 10px; /* Отступ сверху для учета фиксированного меню */
  flex: 1;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}


.portfolio::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.project {
  padding: 15px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.project-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 15px;
}

.project h2 {
  margin: 10px 0;
}

.project p {
  margin-bottom: 15px;
}

.project-links {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.view-project,
.github-link {
  color: #007bff;
  text-decoration: none;
  padding: 5px 10px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.view-project:hover,
.github-link:hover {
  background-color: #e9ecef;
  text-decoration: underline;
}

.github-link i {
  margin-right: 5px;
}

.about-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  flex: 1; /* This will make sure the about-container takes up the remaining space */
  padding-top: 60px; /* Отступ сверху для учета фиксированного меню */
}

.sidebar {
  position: sticky;
  top: 0;
  flex: 0 0 200px;
  padding-right: 20px;
  margin-top: 40px;
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
}

.sidebar a {
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

.section {
  margin-bottom: 40px;
}



.about-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap; /* Отключает перенос текста на другую строку */
}

.about-text {
  margin-left: 20px;
  flex: 1; /* Заполняет оставшееся пространство */
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  background-color: #f0f0f0;
  color: #333;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
  font-size: 14px;
}

footer {
  text-align: center;
  padding: 10px;
  background-color: #f1f1f1;
  color: #333;
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 1; /* Поверх содержимого, но под стрелками карусели */
  box-sizing: border-box;
}

footer a {
  color: #333;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.icon {
  margin-right: 10px;
  font-size: 20px;
}

.about-photo {
  width: 300px;
  height: 300px;
  border-radius: 20px; /* Закругленные углы */
  overflow: hidden;
  margin-bottom: 20px; /* Отступ снизу */
}

.about-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Сохраняет соотношение сторон, заполняя контейнер */
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    padding: 10px;
  }

  .sidebar {
    flex: none;
    width: 100%;
    padding-right: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-around;
    position: static;
    height: auto;
    overflow-y: visible;
  }

  .sidebar a {
    margin: 0;
    padding: 10px;
  }

  .main-content {
    padding: 10px;
  }

  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-text {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
  }

  
}

